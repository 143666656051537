import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'

// pc-header下拉菜单
var headerswiper = new Swiper("header .mySwiper", {
  speed: 600,
  effect : 'fade',
  fadeEffect: {
    crossFade: true,
  },
  allowTouchMove: false,
  on:{
    init: function(swiper){
      $('header .header-nav .sub-nav-tab>div').eq(this.realIndex).addClass('active')
    },
    slideChangeTransitionStart: function(){
      $('header .header-nav .sub-nav-tab>div').eq(this.realIndex).addClass('active').siblings().removeClass('active')
    },
  }
})
$('header .header-nav .sub-nav-tab>div').on('click',function(){
  let index = $(this).index()
  headerswiper.slideTo(index)
})
$('header .header-nav ul li.first>a').on('click',function(){
  let parent = $(this).closest('li')
  if(parent.find('.sub-nav').length > 0){
    if(parent.hasClass('hover')){
      parent.removeClass('hover').find('.sub-nav').slideUp(300)
      // headerswiper.slideTo(0)
    }else{
      parent.addClass('hover').find('.sub-nav').slideDown(300).css('display','flex')
    }
  }
})
$('body').on('click',function(e){
  if($(e.target).parents('header .header-nav ul li.first').length == 0){
    $('header .header-nav ul li').removeClass('hover').find('.sub-nav').slideUp(300)
    // headerswiper.slideTo(0)
  }
})

// 手机端菜单
$('[data-open="cmenu"]').on('click', function () {
  if($('header .hamburger').hasClass('active')){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('.header').removeClass('show-menu')
    $('header .menu-mask').fadeOut(200)
    $('body').css({
      'height':'auto',
      'overflow-y':'auto'
    })
  }else{
    $('header .hamburger').addClass('active')
    $('header .mob-header').addClass('show')
    $('.header').addClass('show-menu')
    $('header .menu-mask').fadeIn(200)
    $('body').css({
      'height':'100vh',
      'overflow-y':'hidden'
    })
  }
})

$(document).ready(function(){
  $('.mob-header ul li').each(function(index){
    if($(this).find('.sub-nav a').length > 0){
      $(this).find('.title .iconfont').css('display','flex')
    }
  })
})

$('.mob-header ul li .sub-nav a').on('click',function(){
  setTimeout(function(){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .menu-mask').fadeOut(200)
    $('body').css({
      'height':'auto',
      'overflow-y':'auto'
    })
  },200)
})

$('.mob-header ul li .title').on('click',function(){
  let $parentEl = $(this).parents('li')
  if($parentEl.hasClass('active')){
    $parentEl.removeClass('active')
    $parentEl.find('.sub-nav').slideUp()
  }else{
    $parentEl.addClass('active').siblings().removeClass('active')
    $parentEl.find('.sub-nav').slideDown()
    $parentEl.siblings().find('.sub-nav').slideUp()
  }
})

$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if(scroH > 50){
    $('.header').addClass('scroll')
  }else{
    $('.header').removeClass('scroll')
  }

});