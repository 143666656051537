module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer class="footer">\r\n  <div class="left">\r\n    <a href="index.html"><img src="' +
((__t = (require('../../assets/images/logo.png'))) == null ? '' : __t) +
'" alt=""></a>\r\n  </div>\r\n  <div class="right">\r\n    <div class="nav">\r\n      <a href="#">TIRES</a>\r\n      <a href="#">TIRES 101</a>\r\n      <a href="#">ABOUT US</a>\r\n      <a href="#">WARRANTY INFO</a>\r\n      <a href="#">ROAD HAZARD</a>\r\n      <a href="#">CONTACT</a>\r\n      <a href="#">MEDIA</a>\r\n    </div>\r\n    <div class="fg"></div>\r\n    <div class="dealer"><a href="#">Dealer Locator</a></div>\r\n    <div class="social">\r\n      <a href="https://www.instagram.com/predatortires/" target="_blank"><i class="iconfont icon-instagram"></i></a>\r\n      <a href="https://www.facebook.com/PredatorTires/" target="_blank"><i class="iconfont icon-facebook"></i></a>\r\n    </div>\r\n  </div>\r\n</footer>';

}
return __p
}