module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title) var title = '';
if (!subtitle) var subtitle = '';
if (!desc) var desc = '';
;
__p += '\r\n<section class="ny-banner">\r\n  <div class="bg bg-cover" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></div>\r\n  <div class="t container">\r\n    <h1 class="wow fadeInUp" data-wow-delay=".1s"><span>' +
((__t = ( title )) == null ? '' : __t) +
'</span></h1>\r\n    <h2 class="wow fadeInUp" data-wow-delay=".2s">' +
((__t = ( subtitle )) == null ? '' : __t) +
'</h2>\r\n    <div class="desc wow fadeInUp" data-wow-delay=".3s">' +
((__t = ( desc )) == null ? '' : __t) +
'</div>\r\n  </div>\r\n</section>';

}
return __p
}